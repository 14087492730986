import { ReactComponent as AppStoreSVG } from '../../assets/images/appStore.svg';
import { ReactComponent as GooglePlaySVG } from '../../assets/images/googleplay.svg';
import { ReactComponent as LicenseSVG } from '../../assets/images/license.svg';
import { ReactComponent as MessageSVG } from '../../assets/images/email.svg';
import { downloadLink, footerText } from '../../constant/Arabic.constant';



const Footer = () => {
  return (
    <div className='h-[320px] bg-[#072718] flex justify-evenly px-8 items-center'>
      <div className='text-[#EFE6DD] flex flex-col items-end gap-4 justify-start  '>
        <div className='flex gap-2 items-center text-2xl '>
          <p>{footerText.licenseTitle}</p>
          <LicenseSVG />
        </div>
        <div className='flex gap-2 items-center text-2xl '>
          <p>{footerText.emailTitle}</p>
          <MessageSVG />
        </div>
      </div>
      <div className='flex flex-col gap-4'>
        <a href={downloadLink?.appStore} target='_blank'><AppStoreSVG className='h-[82px] w-[284px]' /></a>
        <a href={downloadLink?.googlePlay} target='_blank'> <GooglePlaySVG className='h-[82px] w-[284px]' /></a>
      </div>
    </div>
  );
};
export default Footer;
