import { AllText } from '../../constant/Arabic.constant';

const Header = () => {
  return (
    <div className=' flex items-center justify-center h-[488px] bg-[#072718] '>
      {AllText && (
        <div className='flex flex-col gap-12 text-[#EFE6DD] items-center'>
          <h1 className=' text-[64px] font-bold text-center'>
            {AllText?.header?.title}
          </h1>
          <h2 className=' text-[40px]'>{AllText?.header?.description}</h2>
        </div>
      )}
    </div>
  );
};
export default Header;
