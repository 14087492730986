import React, { useEffect } from 'react';
import './Banner.scss';
import { ReactComponent as MainSVG } from '../../assets/images/main-mobile.svg';
import { ReactComponent as SecondSVG } from '../../assets/images/main-mobile-2.svg';
import { ReactComponent as ThirdSVG } from '../../assets/images/main-mobile-3.svg';
import { ReactComponent as LogoSVG } from '../../assets/images/logo-big.svg';

import gsap from 'gsap';

const Banner = () => {
  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1, repeatDelay: 0 });

    // Set initial state for all SVGs
    tl.set(['.main-svg', ".logo-svg"], { x: '-100%', opacity: 1 });
    tl.set(['.second-svg', '.third-svg'], { x: '-100%', opacity: 0 });
    tl.set([".title-one"], { x: '+200%', opacity: 1 })
    tl.set([".title-two"], { x: '-100%', opacity: 1 })



    // First phase: Move all SVGs together from left to right, MainSVG visible
    tl.to(['.second-svg', '.main-svg', '.third-svg'], {
      duration: 1,
      x: (window.innerWidth / 2),
      position: 'absolute',
      xPercent: -50,
    })
      .to(['.main-svg', '.second-svg', '.third-svg'], {
        duration: 1,
        x: (window.innerWidth / 2),
        position: 'absolute',
        xPercent: -50,

      })
      .to(['.third-svg', '.main-svg', '.second-svg',], {
        duration: 1,
        x: (window.innerWidth / 4),
        position: 'absolute',
        xPercent: -50,
      })
      .to('.second-svg', { opacity: 1, duration: 1, ease: 'power1.inOut', }, '<')
      .to('.third-svg, .main-svg', { opacity: 0, duration: 1, ease: 'power1.inOut', }, '<')
      .to(['.title-one'], {
        duration: 1,
        x: (window.innerWidth / 6),
        xPercent: -50,
      })
      .to(['.main-svg', '.second-svg', '.third-svg'], {
        duration: 1,
        x: (window.innerWidth / 4),
        position: 'absolute',
        xPercent: -50,
      })
      .to(['.title-one'], {
        duration: 1,
        x: (window.innerWidth / 6),
        xPercent: -50,

      })
      .to('.second-svg', { opacity: 1, duration: 1, ease: 'power1.inOut', }, '<') // MainSVG visible, others hidden
      .to('.main-svg, .third-svg', { opacity: 0, duration: 1, ease: 'power1.inOut', }, '<')
      .to(['.title-one'], {
        duration: 1,
        x: "200%",
      })
      .to(['.main-svg', '.second-svg', '.third-svg'], {
        duration: 1,
        x: (window.innerWidth / 4) * 3,
        position: 'absolute',
        xPercent: -50,
      })
      .to('.third-svg', { opacity: 1, duration: 1, ease: 'power1.inOut', }, '<')
      .to('.second-svg, .main-svg', { opacity: 0, duration: 1, ease: 'power1.inOut', }, '<')

      .to(['.title-two'], {
        duration: 1,
        x: (window.innerWidth / 8) * 3,
        xPercent: -50,
      })

      .to(['.main-svg', '.second-svg', '.third-svg'], {
        duration: 1,
        x: (window.innerWidth / 4) * 3,
        position: 'absolute',
        xPercent: -50,
      })
      .to(['.title-two'], {
        duration: 1,
        x: (window.innerWidth / 8) * 3,
        xPercent: -50,

      })
      .to(['.title-two'], {
        duration: 1,
        x: '-200%',

      })
      .to(['.main-svg', '.second-svg', '.third-svg'], {
        duration: 1,
        x: '-100%',
      })
      .to(['.logo-svg'], {
        duration: 1,
        x: (window.innerWidth / 2),
        position: 'absolute',
        xPercent: -50,
      })
      .to(['.logo-svg'], {
        duration: 3,
        x: (window.innerWidth / 2),
        position: 'absolute',
        xPercent: -50,
      })
      .to(['.logo-svg'], {
        duration: 1,
        x: '-100%',
        position: 'absolute',
        xPercent: -50,
      })




  }, []);

  return (
    <div className='main '>



      <MainSVG className='svg-element main-svg' />
      <SecondSVG className='svg-element second-svg' />
      <ThirdSVG className='svg-element third-svg' />
      <div className='svg-element logo-svg flex flex-col gap-4'>
        <LogoSVG />
        <p className='title-three text-[48px] font-bold text-white'>
          رفيقك للجنان
        </p>
      </div>

      <p dir='rtl' className='title-two text-3xl font-bold leading-[90px] text-white w-[1000px]  text-[48px] '>

        ظهور الأعمال الخاصة

        <span className='bg-white p-4 text-[30px] font-thin mx-4 rounded-2xl border-4 border-[#B7FFD8] text-black whitespace-nowrap'>بكل يوم</span >{' '}


        بنقرة واحدة ومتزامنة مع الثبوت الشرعي للأشهر القمرية.

      </p>
      <div className='title-one text-[48px] font-bold text-white w-[1000px] flex flex-col  whitespace-normal absolute'>
        <p>
          فهرسة جديدة وسهلة لمواضيع الكتاب تيسر الوصول إلى مضامينه
        </p>
        <p className='text-[40px] font-thin '>
          مع محافظتنا على فهرسة الشيخ المصنف(قدس سره).
        </p>
      </div>

    </div>


  );
};

export default Banner;