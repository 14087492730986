import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Core Swiper styles
import 'swiper/css/navigation'; // Navigation module styles
import 'swiper/css/pagination'; // Pagination module styles

// Import Swiper modules
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { ReactComponent as AvatarSVG } from '../../assets/images/avatar.svg';
import { ReactComponent as StarSVG } from '../../assets/images/Star.svg';

const commentsData = [
  {
    avatar: AvatarSVG,
    name: 'Tholfaqar Aliraqi91',
    date: '23 يوليو 2024',
    comment:
      'احسن الله لكم على هذا التطبيق الجميل والرائع ووفقكم الله الى اكثر واكثر. والله كلش مستفاد منه وحشركم الله مع محمد وال محمد. لان نفعتوني بي هذا التطبيق واريد اشكركم عليه وبارك الله فيكم. وخصوصا التحديث الجديد كل ممتاز صار',
  },
  {
    avatar: AvatarSVG,
    name: 'John Doe',
    date: '21 يوليو 2024',
    comment: 'تطبيق رائع ومفيد جداً، أشكر الفريق على هذا الجهد الجبار!',
  },
  {
    avatar: AvatarSVG,
    name: 'Tholfaqar Aliraqi91',
    date: '23 يوليو 2024',
    comment:
      'احسن الله لكم على هذا التطبيق الجميل والرائع ووفقكم الله الى اكثر واكثر. والله كلش مستفاد منه وحشركم الله مع محمد وال محمد. لان نفعتوني بي هذا التطبيق واريد اشكركم عليه وبارك الله فيكم. وخصوصا التحديث الجديد كل ممتاز صار',
  },
  {
    avatar: AvatarSVG,
    name: 'Tholfaqar Aliraqi91',
    date: '23 يوليو 2024',
    comment:
      'احسن الله لكم على هذا التطبيق الجميل والرائع ووفقكم الله الى اكثر واكثر. والله كلش مستفاد منه وحشركم الله مع محمد وال محمد. لان نفعتوني بي هذا التطبيق واريد اشكركم عليه وبارك الله فيكم. وخصوصا التحديث الجديد كل ممتاز صار',
  },
  {
    avatar: AvatarSVG,
    name: 'Tholfaqar Aliraqi91',
    date: '23 يوليو 2024',
    comment:
      'احسن الله لكم على هذا التطبيق الجميل والرائع ووفقكم الله الى اكثر واكثر. والله كلش مستفاد منه وحشركم الله مع محمد وال محمد. لان نفعتوني بي هذا التطبيق واريد اشكركم عليه وبارك الله فيكم. وخصوصا التحديث الجديد كل ممتاز صار',
  },
  {
    avatar: AvatarSVG,
    name: 'Tholfaqar Aliraqi91',
    date: '23 يوليو 2024',
    comment:
      'احسن الله لكم على هذا التطبيق الجميل والرائع ووفقكم الله الى اكثر واكثر. والله كلش مستفاد منه وحشركم الله مع محمد وال محمد. لان نفعتوني بي هذا التطبيق واريد اشكركم عليه وبارك الله فيكم. وخصوصا التحديث الجديد كل ممتاز صار',
  },
  {
    avatar: AvatarSVG,
    name: 'Tholfaqar Aliraqi91',
    date: '23 يوليو 2024',
    comment:
      'احسن الله لكم على هذا التطبيق الجميل والرائع ووفقكم الله الى اكثر واكثر. والله كلش مستفاد منه وحشركم الله مع محمد وال محمد. لان نفعتوني بي هذا التطبيق واريد اشكركم عليه وبارك الله فيكم. وخصوصا التحديث الجديد كل ممتاز صار',
  },
  {
    avatar: AvatarSVG,
    name: 'Tholfaqar Aliraqi91',
    date: '23 يوليو 2024',
    comment:
      'احسن الله لكم على هذا التطبيق الجميل والرائع ووفقكم الله الى اكثر واكثر. والله كلش مستفاد منه وحشركم الله مع محمد وال محمد. لان نفعتوني بي هذا التطبيق واريد اشكركم عليه وبارك الله فيكم. وخصوصا التحديث الجديد كل ممتاز صار',
  },
  {
    avatar: AvatarSVG,
    name: 'Tholfaqar Aliraqi91',
    date: '23 يوليو 2024',
    comment:
      'احسن الله لكم على هذا التطبيق الجميل والرائع ووفقكم الله الى اكثر واكثر. والله كلش مستفاد منه وحشركم الله مع محمد وال محمد. لان نفعتوني بي هذا التطبيق واريد اشكركم عليه وبارك الله فيكم. وخصوصا التحديث الجديد كل ممتاز صار',
  },
  {
    avatar: AvatarSVG,
    name: 'Tholfaqar Aliraqi91',
    date: '23 يوليو 2024',
    comment:
      'احسن الله لكم على هذا التطبيق الجميل والرائع ووفقكم الله الى اكثر واكثر. والله كلش مستفاد منه وحشركم الله مع محمد وال محمد. لان نفعتوني بي هذا التطبيق واريد اشكركم عليه وبارك الله فيكم. وخصوصا التحديث الجديد كل ممتاز صار',
  },

  // You can repeat similar objects to have 10 items
];
const Comments: React.FC = () => {
  return (
    <div
      className='h-[820px] w-full bg-[#B5A476] bg-opacity-[18%] flex flex-col justify-between px-8'
      dir='rtl'
    >
      <p className='text-[64px] font-bold whitespace-nowrap '>آراء المستخدمن</p>
      <div className='flex justify-center items-center h-full'>
        <Swiper
          className='mySwiper'
          slidesPerView={'auto'}
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={30}
          loop={true}
          autoplay={{ delay: 0, disableOnInteraction: true, reverseDirection: false }}
          speed={2000}
          freeMode={true}

        >
          {commentsData.map((comment, index) => (
            <SwiperSlide
              key={index}
              className='max-w-[692px] min-h-[455px] bg-white rounded-2xl shadow-xl p-12 flex flex-col gap-8'
            >
              <div className='flex items-center gap-4'>
                <comment.avatar />
                <p className='text-xl font-bold'>{comment.name}</p>
              </div>
              <div className='flex gap-4 items-center pt-8'>
                <StarSVG />
                <p>{comment.date}</p>
              </div>
              <p className='text-xl text-justify pt-8'>{comment.comment}</p>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Comments;
