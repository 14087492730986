import MobileVideoSVG from '../mobilePlayer/MobilePlayer';
import firstVideo from '../../assets/video/secondPage.mp4';
import { appFeaturesSecondPage } from '../../constant/Arabic.constant';




const SecondPage = () => {
  return (
    <div
      className='h-[100vh] w-full bg-[#B5A4762E] bg-opacity-[18%]  flex items-center justify-center p-8'
      dir='rtl'
    >
      <div className=' w-full   p-8'>
        <div className='flex items-center gap-8'>

          <MobileVideoSVG videoUrl={firstVideo} />
          <div className='flex flex-col gap-6'>
            <h2 className='text-[50px] font-bold'>
              عرض صوتيات للكثير من الأدعية وبأصوات القراء المشهورين
            </h2>
            {appFeaturesSecondPage.features.map((feature, index) => (
              <div key={index} className='flex  items-center gap-2 '>
                <h3 className='text-[30px] whitespace-nowrap font-bold'>
                  {feature.feature}:
                </h3>
                <p className='text-[30px]  mt-2'>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondPage;
