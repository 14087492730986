import React from 'react';
import { ReactComponent as MenhajSalehinSVG } from '../../assets/images/menhaj.svg';
import { ReactComponent as TartilSVG } from '../../assets/images/tartil.svg';
import { ReactComponent as RejalSVG } from '../../assets/images/rejal.svg';
import { ReactComponent as NebrasSVG } from '../../assets/images/nebras.svg';
import { ReactComponent as SahifieSVG } from '../../assets/images/sahifie.svg';
import { ReactComponent as SajadieSVG } from '../../assets/images/sajadie.svg';

export const products = [
  {
    title: 'منهاج الصالحين',
    icon: MenhajSalehinSVG,
  },
  {
    title: 'الحديث الشريف',
    icon: SahifieSVG,
  },
  {
    title: 'ترتيل',
    icon: TartilSVG,
  },
  {
    title: 'رجال الحديث',
    icon: RejalSVG,
  },
  {
    title: 'نبراس الزائرين',
    icon: NebrasSVG,
  },
  {
    title: 'الصحيفة السجادية',
    icon: SajadieSVG,
  },
];

const Products = () => {
  return (
    <div className='h-[700px] p-14' dir='rtl'>
      <h2 className='text-[64px] font-bold'>المزيد من التطبيقات</h2>
      <div className='w-full flex gap-4 justify-evenly items-center h-full'>
        {products.map((product, index) => (
          <div key={index} className='flex flex-col items-center'>
            <product.icon className='w-[223px] h-[223px] mb-4' />
            <h3 className='text-xl font-semibold'>{product.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;
