import React from 'react';

import Introductions from './components/introductions/Introductions';
import Header from './components/header/Header';
import Banner from './components/HorizentalContainer/Banner';
import DownloadLink from './components/downloadLink/DownloadLink';
import FirstPage from './components/pages/FirstPage';
import SecondPage from './components/pages/SecondPage';
import ThirdPage from './components/pages/ThirdPage';
import Footer from './components/footer/Footer';
import Products from './components/otherProducts/Products';
import Comments from './components/comments/Comments';

const App: React.FC = () => {
  return (
    <div className='overflow-hidden bg-[#EFE6DD] relative'>
      <Introductions />
      <Header />
      <Banner />
      <DownloadLink />
      <FirstPage />
      <SecondPage />
      <ThirdPage />
      <Comments />
      <Products />
      <Footer />
    </div>
  );
};

export default App;
