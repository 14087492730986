import {link} from 'fs';
import {title} from 'process';

export interface MenuItem {
  title: string;
  id: number;
  link: string;
}

export interface Logo {
  title: string;
  description: string;
}

export interface Header {
  title: string;
  description: string;
}

export interface Links {
  googlePlay: string;
  appStore: string;
}

export interface AllTextType {
  menu: {
    items: MenuItem[];
  };
  logo: Logo;
  header: Header;
  links: Links;
}

export const downloadLink = {
  googlePlay:
    'https://play.google.com/store/apps/details?id=org.masaha.mafatih_a',
  appStore:
    'https://apps.apple.com/us/app/%D9%85%D9%81%D8%A7%D8%AA%D9%8A%D8%AD-%D8%A7%D9%84%D8%AC%D9%86%D8%A7%D9%86/id689778571',
};

export const appFeaturesFirstPage = {
  title: 'ما يميز تطبيق مفاتيح الجنان',
  features: [
    {
      feature: 'قائمة القراء القابلة للتخصيص',
      description: 'قم بتخصيص قرائك المفضلين.',
    },
    {
      feature: 'تشغيل سريع',
      description: 'استمتع بتجربة صوتية سلسة وسريعة دون الحاجة للتحميل.',
    },
    {
      feature: 'وضع مصغر',
      description:
        'استمع للأدعية أثناء قراءتك للدعاء أو استخدام باقي ميزات التطبيق.',
    },
    {
      feature: 'بحث عن القارئ',
      description: 'ابحث بسرعة في قائمة بأكثر من خمسين قارئ.',
    },
  ],
};

export const footerText = {
  googlePlayLink: '',
  appStoreLink: '',
  emailTitle: 'كافة الحقوق محفوظة لـ مساحة حرة / 2011-2021',
  licenseTitle: 'تواصل معنا عبر البريد الالكتروني :info@masaha.org',
};

export const appFeaturesSecondPage = {
  title: 'ما يميز تطبيق مفاتيح الجنان',
  features: [
    {
      feature: 'قائمة القراء القابلة للتخصيص',
      description: 'قم بتخصيص قرائك المفضلين.',
    },
    {
      feature: 'تشغيل سريع',
      description: 'استمتع بتجربة صوتية سلسة وسريعة دون الحاجة للتحميل.',
    },
    {
      feature: 'وضع مصغر',
      description:
        'استمع الأدعية أثناء قراءتك للدعاء أو استخدام باقي ميزات التطبيق.',
    },
    {
      feature: 'بحث عن القارئ',
      description: 'ابحث بسرعة في قائمة بأكثر من خمسين قارئ.',
    },
  ],
};

export const appFeaturesThirdPage = {
  title: 'ما يميز تطبيق مفاتيح الجنان',
  features: [
    {
      feature: 'قائمة القراء القابلة للتخصيص',
      description: 'قم بتخصيص قرائك المفضلين.',
    },
    {
      feature: 'تشغيل سريع',
      description: 'استمتع بتجربة صوتية سلسة وسريعة دون الحاجة للتحميل.',
    },
    {
      feature: 'وضع مصغر',
      description:
        'استمع الأدعية أثناء قراءتك للدعاء أو استخدام باقي ميزات التطبيق.',
    },
    {
      feature: 'بحث عن القارئ',
      description: 'ابحث بسرعة في قائمة بأكثر من خمسين قارئ.',
    },
  ],
};

export const AllText: AllTextType = {
  menu: {
    items: [
      {title: 'اتصل', id: 1, link: 'https://www.masaha.org/'},
      {title: 'مساحة حرة ', id: 2, link: 'https://www.masaha.org/'},
    ],
  },
  logo: {
    title: 'مفاتيح',
    description: 'الجنان',
  },
  header: {
    title: 'تطبيق مفاتيح الجنان الجديد كلياً',
    description: 'الإصدار الثالث',
  },
  links: {
    googlePlay: '',
    appStore: '',
  },
};
