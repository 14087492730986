import { downloadLink } from '../../constant/Arabic.constant';
import { ReactComponent as AppStoreSVG } from '../../assets/images/appStore.svg';
import { ReactComponent as GooglePlaySVG } from '../../assets/images/googleplay.svg';

const DownloadLink = () => {
  return (
    <div className='h-[221px] w-full bg-[#B5A476]  flex items-center justify-between  px-8'>
      <div className='flex  gap-4'>
        <a href={downloadLink?.appStore} target='_blank'><AppStoreSVG className='h-[82px] w-[284px]' /> </a>
        <a href={downloadLink?.googlePlay} target='_blank'> <GooglePlaySVG className='h-[82px] w-[284px]' /></a>
      </div>
      <p className='text-[50px]'>حمله الآن</p>
    </div>
  );
};
export default DownloadLink;
