import { ReactComponent as MobileSVG } from '../../assets/images/mobile-1.svg';

const MobileVideoSVG = ({ videoUrl }: { videoUrl: string }) => {
    return (
        <div className="relative w-full max-w-md h-auto">
            <MobileSVG />
            <video
                className="absolute mx-1 my-3 rounded-[30px] bottom-0 w-[294px] h-[640px] left-0  p-1  overflow-hidden pointer-events-none object-cover"
                autoPlay
                muted
                loop
            >
                <source src={videoUrl} type="video/mp4" />
            </video>
        </div>
    );
};

export default MobileVideoSVG;
