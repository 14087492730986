import { appFeaturesThirdPage } from '../../constant/Arabic.constant';
import firstVideo from '../../assets/video/thirdPage.mp4';
import MobileVideoSVG from '../mobilePlayer/MobilePlayer';




const ThirdPage = () => {
  return (
    <div
      className='h-[100vh] w-full bg-[#EFE6DD] flex items-center justify-center p-8'
      dir='rtl'
    >
      <div className=' w-full   p-8'>
        <div className='flex items-center'>
          <div className='flex flex-col gap-6'>
            <h2 className='text-[50px] font-bold'>
              عرض صوتيات للكثير من الأدعية وبأصوات القراء المشهورين
            </h2>
            {appFeaturesThirdPage.features.map((feature, index) => (
              <div key={index} className='flex  items-center gap-2 '>
                <h3 className='text-[30px] whitespace-nowrap font-bold'>
                  {feature.feature}:
                </h3>
                <p className='text-[30px]  mt-2'>{feature.description}</p>
              </div>
            ))}
          </div>
          <MobileVideoSVG videoUrl={firstVideo} />
        </div>
      </div>
    </div>
  );
};

export default ThirdPage;
