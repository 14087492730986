import { AllText } from '../../constant/Arabic.constant';

const Introductions = () => {
  return (
    <>
      <header className='fixed top-0  z-50 w-full flex flex-col justify-between items-center p-4  bg-[#072718] overflow-hidden'>
        <div className='flex w-full  justify-between'>
          <div className='flex'>
            {AllText?.menu?.items?.map((menuItem, id) => (
              <a
                key={id}
                href={`${menuItem?.link}`}
                target='_blank'
                rel='noopener noreferrer'
                className=' text-[#EFE6DD] px-4 py-2 rounded  whitespace-nowrap m'
              >
                {menuItem?.title}
              </a>
            ))}
          </div>
          {AllText?.logo && (
            <div className='flex gap-2 items-center'>
              <div className='flex flex-col text-[#EFE6DD]  '>
                <p className=''>{AllText?.logo?.title}</p>
                <p className=''>{AllText?.logo?.description}</p>
              </div>
              <img
                loading='eager'
                alt='logo'
                src={'/logo.png'}
                className='h-[75px] w-[75px] rounded-2xl'
              />
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default Introductions;
